$(document).ready(function () {
  const deleteModal = $('#modal-delete-price');
  const deleteForm = deleteModal.find('form');

  function replacePrice(row) {
    const cells = row.find('td');
    const priceCell = $(cells[cells.length - 2]);
    const currentPrice = priceCell.text();
    priceCell.html(`
          <input
            type="text"
            class="form-control text-right input-sm js-price-field"
            value="${currentPrice}"
            name="price"
          >
        `);
    priceCell.find('.js-price-field').numeric({
      negative: false,
      decimalPlaces: 3,
    });

    // возвращаем функцию, делающую обратное
    return function () {
      priceCell.html(currentPrice);
    }
  }

  function replaceActions(row, returnTextPrice) {
    const cells = row.find('td');
    const actionsCell = $(cells[cells.length - 1]);
    const actionsBtnGroup = actionsCell.find('.btn-group');
    const newActions = $(`
          <div class="btn-group btn-group-xs">
            <button class="btn btn-success js-save">
              <i class="fa fa-check"></i>
            </button>
            <button class="btn btn-danger js-cancel">
              <i class="fa fa-times"></i>
            </button>
          </div>
        `);

    actionsBtnGroup.hide();
    actionsCell.append(newActions);

    newActions.find('.js-cancel').on('click', function () {
      newActions.remove();
      actionsBtnGroup.show();
      returnTextPrice();
    });

    function sendForm() {
      const csrfToken = getCsrfToken();
      const newPrice = row.find('input[name="price"]').val();
      const url = actionsBtnGroup.find('.js-edit').data('url');

      const data = {
        csrfmiddlewaretoken: csrfToken,
        price: newPrice,
      };
      $.ajax({
        method: 'post',
        data,
        url,
        success(res) {
          if (res['status'] === 'success') {
            location.reload();
          } else {

          }
        }
      });
    }

    newActions.find('.js-save').on('click', sendForm);
    row.find('input').on('keydown', function (e) {
      if (e.keyCode === 13) {
        sendForm();
      }
    });
  }

  $('.js-edit').on('click', function (e) {
    e.preventDefault();
    const row = $(this).parents('tr');
    const returnTextPrice = replacePrice(row);
    replaceActions(row, returnTextPrice);
  });

  $('.js-delete').on('click', function (e) {
    e.preventDefault();
    const url = $(this).data('url');
    const target = new URL(url, location.origin);
    target.searchParams.append('prev', location.href.toString());
    deleteModal.modal('show');
    deleteForm.attr('action', target.toString());
  });
});
